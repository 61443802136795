import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';
import { func } from 'prop-types';
import MagnifierIcon from 'svgs/careers/magnifier.svg';

const SContainer = styled.div`
    height: 18rem;
    padding: 3rem 2rem;
    background: var(--tertiary-color);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    text-align: center;
`;

const STitle = styled(HeaderThird)`
    color: var(--white-color);
`;

const SParagraph = styled(Paragraph)`
    color: var(--white-color);
    font-size: 0.875rem;
`;

const SButton = styled.button`
    color: var(--white-color);
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
`;

export const SendApplicationCard = ({ openModal }) => {
    return (
        <SContainer>
            <MagnifierIcon />
            <STitle>
                <FormattedMessage id="view-careers.vacancies.didntFind" />
            </STitle>
            <SParagraph>
                <FormattedMessage
                    id="view-careers.vacancies.noWorries"
                    values={{
                        link: (chunks) => (
                            <SButton aria-label={chunks} onClick={openModal}>
                                {chunks}
                            </SButton>
                        ),
                    }}
                />
            </SParagraph>
        </SContainer>
    );
};

SendApplicationCard.propTypes = {
    openModal: func.isRequired,
};
