import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';

const VIMEO_VIDEO_ID = '788967704';

const SVideoContainer = styled.div`
    max-width: 63.5rem;
    height: 35.75rem;
    margin: 0 auto;
    cursor: pointer;

    > :first-child {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
    }

    ${CONSTANTS.MEDIA.max1200`
        max-width: 54.6rem;
        height: 30.75rem;

        > :first-child {
            margin: 0 auto;
        }      
    `}

    ${CONSTANTS.MEDIA.max1024`
        max-width: 44rem;
        height: 24.75rem;
    `}

    ${CONSTANTS.MEDIA.max768`
        max-width: 33.3rem;
        height: 18.75rem;
    `}

    ${CONSTANTS.MEDIA.max600`
        max-width: 27.4rem;
        height: 15.4375rem;
    `}

    ${CONSTANTS.MEDIA.max500`
        max-width: 21.5rem;
        height: 12.125rem;
    `}
`;

export const TeamVacationVimeo = () => {
    const { formatMessage } = useIntl();

    useEffect(() => {
        import('@slightlyoff/lite-vimeo');
    }, []);

    return (
        <Container>
            <SVideoContainer>
                <lite-vimeo
                    videoid={VIMEO_VIDEO_ID}
                    videotitle={formatMessage({
                        id: 'view-careers.teamIntegration',
                    })}
                />
            </SVideoContainer>
        </Container>
    );
};
