import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { bool, func, node, object, string } from 'prop-types';

const SContainer = styled.div`
    display: flex;
`;

const SSubContainer = styled.div`
    display: flex;
    position: relative;
`;

const SGatsbyImage = styled(GatsbyImage)`
    cursor: pointer;
    z-index: 2;
`;

const SSvgFrameContainer = styled.div`
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`;

export const InteractiveAvatar = ({
    children,
    image,
    value,
    isSelected,
    onClick,
}) => {
    return (
        <SContainer>
            <SSubContainer>
                <button type="button" disabled={isSelected} onClick={onClick}>
                    <SGatsbyImage
                        image={image}
                        alt={`${value} avatar`}
                        isSelected={isSelected}
                    />
                    <SSvgFrameContainer isSelected={isSelected}>
                        {children}
                    </SSvgFrameContainer>
                </button>
            </SSubContainer>
        </SContainer>
    );
};

InteractiveAvatar.defaultProps = {
    isSelected: false,
};

InteractiveAvatar.propTypes = {
    children: node.isRequired,
    image: object.isRequired,
    value: string.isRequired,
    isSelected: bool.isRequired,
    onClick: func.isRequired,
};
