import { graphql, useStaticQuery } from 'gatsby';

export const useTeamAvatars = () => {
    const images = useStaticQuery(graphql`
        {
            basia: file(relativePath: { eq: "careers/basia-avatar.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FIXED
                    )
                }
            }
            bartek: file(relativePath: { eq: "careers/bartek-avatar.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FIXED
                    )
                }
            }
            przemek: file(relativePath: { eq: "careers/przemek-avatar.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FIXED
                    )
                }
            }
            magda: file(relativePath: { eq: "careers/magda-avatar.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FIXED
                    )
                }
            }
        }
    `);

    return images;
};
