import { yupResolver } from '@hookform/resolvers/yup';
import { ERROR_MESSAGE } from 'constants/form-constants';
import * as yup from 'yup';

export const FORM_FIELDS = {
    FIRST_NAME: 'firstName',
    EMAIL: 'email',
};

export const validationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.FIRST_NAME]: yup
            .string()
            .required(ERROR_MESSAGE.REQUIRED_FIELD),
        [FORM_FIELDS.EMAIL]: yup
            .string()
            .email(ERROR_MESSAGE.EMAIL)
            .required(ERROR_MESSAGE.REQUIRED_FIELD),
    }),
);
