import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { CONSTANTS } from 'constants/styles/constants';
import { Link } from 'gatsby';
import { bool, string } from 'prop-types';
import ArrowIcon from 'svgs/careers/arrow-right.svg';

const featured = css`
    border: 2px solid var(--additional-color);
    box-shadow: 0.25rem 0.25rem 0px var(--additional-color),
        0px 0px 2.625rem var(--additional-color-50);
`;

const SContainer = styled.div`
    height: 18rem;
    padding: 2.5rem 1.875rem;
    background: var(--white-color);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${({ isFeatured }) => isFeatured && featured};

    ${CONSTANTS.MEDIA.max600`
        align-items: flex-start;
        justify-content: space-between;
    `}
`;

const STitle = styled(HeaderThird)`
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-bottom: 0.4rem;
`;

const SFeaturedLabelContainer = styled.div`
    max-width: 10.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.125rem;
    border-radius: 2rem;
    border: 2px solid var(--additional-color);
    text-transform: uppercase;
    color: var(--additional-color);
    font-weight: 700;
    font-size: 0.75rem;

    ${CONSTANTS.MEDIA.max600`
        margin-bottom: 1rem;
    `}
`;

const SSalaryLabel = styled.span`
    font-weight: 600;
    font-size: 1.125rem;

    &:before {
        content: '•';
        color: var(--tertiary-color);
        margin-right: 0.5rem;
    }
`;

const SLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: var(--tertiary-color);
`;

export const VacancyCard = ({ slug, jobTitle, salary, isFeatured }) => {
    return (
        <SContainer isFeatured={isFeatured}>
            <div>
                {isFeatured && (
                    <SFeaturedLabelContainer>
                        <FormattedMessage id="view-careers.vacancies.featuredOffer" />
                    </SFeaturedLabelContainer>
                )}
                <STitle>{jobTitle}</STitle>
                <SSalaryLabel>{salary}</SSalaryLabel>
            </div>
            <SLink to={slug}>
                <FormattedMessage id="view-careers.vacancies.checkOffer" />
                <ArrowIcon />
            </SLink>
        </SContainer>
    );
};

VacancyCard.propTypes = {
    slug: string.isRequired,
    jobTitle: string.isRequired,
    salary: string.isRequired,
    isFeatured: bool,
};

VacancyCard.defaultProps = {
    isFeatured: false,
};
