import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';

import { QuoteSelection } from './quote-selection';

const SContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12.5rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    margin-bottom: 4.75rem;
    color: var(--white-color);
    text-align: center;
    padding: 0 2rem;

    .top-paragraph {
        color: var(--additional-color);
        letter-spacing: 0.2em;
    }

    > :nth-child(2) {
        line-height: 2.875rem;
    }

    ${CONSTANTS.MEDIA.max420`
        padding: 0;
        margin-bottom: 2.75rem;
    `}
`;

export const TeamOpinion = () => {
    return (
        <SContainer>
            <SHeaderSecond topParagraph="view-careers.teamOpinion.title">
                <FormattedMessage id="view-careers.teamOpinion.description" />
            </SHeaderSecond>
            <QuoteSelection />
        </SContainer>
    );
};
