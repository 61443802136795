import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';

import { ContactInfo } from './components/contact-info';
import { TeamMembers } from './components/team-members';

const SContainer = styled(Container)`
    display: flex;
    margin-bottom: 0;

    ${CONSTANTS.MEDIA.max900`
       flex-direction: column;
       align-items: center;
    `};
`;

export const GetInTouch = () => {
    return (
        <SContainer>
            <ContactInfo />
            <TeamMembers />
        </SContainer>
    );
};
