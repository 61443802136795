import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { NewsletterBanner } from 'components/newsletter-banner';
import { useDisableBodyScroll } from 'hooks/use-disable-body-scroll';
import { CareersSection } from 'modules/careers-section';
import { arrayOf, object, shape, string } from 'prop-types';

import { GetInTouch } from './components/get-in-touch';
import { PerksAndBenefits } from './components/perks-and-benefits';
import { RecruitModalForm } from './components/recruit-modal-form';
import { TeamFramedImage } from './components/team-framed-image';
import { TeamOpinion } from './components/team-opinion';
import { TeamVacationVimeo } from './components/team-vacation-vimeo';
import { VacanciesGrid } from './components/vacancies-grid';

const SContainer = styled.div`
    width: 100%;
    background-color: var(--secondary-color);

    .careersContainer {
        background-color: hsla(223, 78%, 8%, 1);
        background-image: radial-gradient(
                at 30% 35%,
                hsla(187, 57%, 53%, 0.25) 0,
                transparent 30%
            ),
            radial-gradient(
                at 70% 50%,
                hsla(302, 64%, 36%, 0.25) 0,
                transparent 30%
            );
    }

    * > ul {
        margin-bottom: 7.5rem;
    }

    > :first-child {
        > :first-child {
            > :nth-child(2) {
                font-size: 3.625rem;
            }
        }
    }
`;

export const CareersView = ({ offers, location }) => {
    const [isBodyScrollDisabled, setIsBodyScrollDisabled] = useState(false);

    const gridRef = useRef();
    const dialogRef = useRef();
    useDisableBodyScroll(isBodyScrollDisabled);

    const openRecruitModal = () => {
        dialogRef?.current?.showModal();
        setIsBodyScrollDisabled(true);
    };

    const enableBodyScroll = () => setIsBodyScrollDisabled(false);

    const scrollToVacancies = () => {
        gridRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    const isPL = location.pathname.split('/')[1] === 'pl';
    return (
        <>
            <SContainer>
                <CareersSection
                    shortContentCallback={scrollToVacancies}
                    containerClassName="careersContainer"
                >
                    <PerksAndBenefits as="div">
                        <TeamFramedImage />
                    </PerksAndBenefits>
                </CareersSection>
                <TeamOpinion />
                <VacanciesGrid
                    ref={gridRef}
                    offers={offers}
                    isPL={isPL}
                    openModal={openRecruitModal}
                />
                <TeamVacationVimeo />
                <NewsletterBanner />
                <GetInTouch />
            </SContainer>
            <RecruitModalForm
                enableBodyScroll={enableBodyScroll}
                dialogRef={dialogRef}
            />
        </>
    );
};

CareersView.propTypes = {
    location: object.isRequired,
    offers: arrayOf(
        shape({
            node: shape({
                title: string,
            }),
        }),
    ).isRequired,
};
