import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { fadeIn } from 'styles/utilities';
import BartekSelectedFrame from 'svgs/careers/bartek-selected-frame.svg';
import BasiaSelectedFrame from 'svgs/careers/basia-selected-frame.svg';
import MagdaSelectedFrame from 'svgs/careers/magda-selected-frame.svg';
import PrzemekSelectedFrame from 'svgs/careers/przemek-selected-frame.svg';
import QuoteUpIcon from 'svgs/icons/ic-quote-up.svg';

import { InteractiveAvatar } from './components/interactive-avatar';
import { MEMBERS_DATA } from './constants';
import { useTeamAvatars } from './hooks';

const SContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 3.75rem;

    ${CONSTANTS.MEDIA.max1200`
        gap: 2.75rem;
    `}

    ${CONSTANTS.MEDIA.max900`
        gap: 0.75rem;
        margin-bottom: 4.25rem;
    `}

    ${CONSTANTS.MEDIA.max700`
        flex-direction: column;
        gap: 2.5rem;
    `}

    ${CONSTANTS.MEDIA.max420`
        gap: 2rem;
    `}
`;

const commonAvatarWrapperStyles = css`
    display: flex;
    flex-direction: column;
    flex: 0.2;

    ${CONSTANTS.MEDIA.max1024`
        gap: 1.5rem;
    `}

    ${CONSTANTS.MEDIA.max900`
        align-items: center;
        justify-content: space-around;
    `}

    ${CONSTANTS.MEDIA.max700`
        flex-direction: row;
    `}

    ${CONSTANTS.MEDIA.max440`
        justify-content: space-evenly;
        gap: 0;
        transform: scale(0.98);
    `}
`;

const SLeftWrapper = styled.div`
    ${commonAvatarWrapperStyles};
    order: 1;
    gap: 0.75rem;

    > :first-child {
        justify-content: flex-end;
    }

    ${CONSTANTS.MEDIA.max900`
        > :first-child {
            align-self: unset;
            margin-bottom: 0;
        }
    `}
`;

const SMiddleWrapper = styled.div`
    display: flex;
    visibility: ${({ startAnimation }) =>
        startAnimation ? 'visible' : 'hidden'};
    flex-direction: column;
    flex: 0.6;
    max-height: 13.5rem;
    align-items: center;
    padding-top: 0.5rem;
    order: 2;
    animation: ${({ startAnimation }) =>
        startAnimation
            ? css`
                  ${fadeIn} 2s
              `
            : 'none'};

    ${CONSTANTS.MEDIA.max1200`
        flex: 0.55;
    `}

    ${CONSTANTS.MEDIA.max700`
        order: 3;
    `}
`;

const SRightWrapper = styled.div`
    ${commonAvatarWrapperStyles};
    order: 3;
    gap: 0.25rem;

    > :nth-child(2) {
        justify-content: flex-end;
    }

    ${CONSTANTS.MEDIA.max900`
        > :first-child {
            margin-bottom: 0;
        }

        > :nth-child(2) {
            align-self: unset;
        }
    `}

    ${CONSTANTS.MEDIA.max700`
        order: 2;
    `}
`;

const SQuoteIconWrapper = styled.div`
    min-width: 3.375rem;
    height: 2.375rem;
    margin-bottom: 1rem;

    ${CONSTANTS.MEDIA.max900`
        min-width: 2.375rem;
        height: 1.75rem;
    `}
`;

const SBlockquote = styled.blockquote`
    margin-bottom: 2rem;
    padding: 0 2.5rem;
    text-align: center;
    font-size: 1.375rem;
    line-height: 2.125rem;
    font-style: italic;
    color: var(--white-color);

    ${CONSTANTS.MEDIA.max700`
        margin-bottom: 1rem;
    `}

    ${CONSTANTS.MEDIA.max420`
        padding: 0;
    `}
`;

const SAuthorSpan = styled.span`
    display: contents;
    font-weight: 700;
    font-size: 1.125rem;
    color: var(--additional-color);

    > :nth-child(2) {
        font-weight: 400;
    }
`;

export const QuoteSelection = () => {
    const [selectedMemberData, setSelectedMemberData] = useState(
        MEMBERS_DATA.PRZEMEK,
    );
    const [startAnimation, setStartAnimation] = useState(true);

    const images = useTeamAvatars();

    const handleAvatarClick = (memberData) => () => {
        setSelectedMemberData(memberData);
        setStartAnimation(false);
        setTimeout(() => setStartAnimation(true));
    };

    const isSelected = (assignedValue) =>
        selectedMemberData.value === assignedValue;

    return (
        <SContainer>
            <SLeftWrapper>
                <InteractiveAvatar
                    image={images.bartek.childImageSharp.gatsbyImageData}
                    value={MEMBERS_DATA.BARTEK.value}
                    isSelected={isSelected(MEMBERS_DATA.BARTEK.value)}
                    onClick={handleAvatarClick(MEMBERS_DATA.BARTEK)}
                >
                    <BartekSelectedFrame />
                </InteractiveAvatar>
                <InteractiveAvatar
                    image={images.magda.childImageSharp.gatsbyImageData}
                    value={MEMBERS_DATA.MAGDA.value}
                    isSelected={isSelected(MEMBERS_DATA.MAGDA.value)}
                    onClick={handleAvatarClick(MEMBERS_DATA.MAGDA)}
                >
                    <MagdaSelectedFrame />
                </InteractiveAvatar>
            </SLeftWrapper>
            <SMiddleWrapper startAnimation={startAnimation}>
                <SQuoteIconWrapper role="presentation">
                    <QuoteUpIcon width="100%" height="100%" />
                </SQuoteIconWrapper>
                <SBlockquote>
                    <FormattedMessage id={selectedMemberData.quote} />
                </SBlockquote>
                <SAuthorSpan>
                    <FormattedMessage id={selectedMemberData.fullName} />
                    <FormattedMessage id={selectedMemberData.role} />
                </SAuthorSpan>
            </SMiddleWrapper>
            <SRightWrapper>
                <InteractiveAvatar
                    image={images.basia.childImageSharp.gatsbyImageData}
                    value={MEMBERS_DATA.BASIA.value}
                    isSelected={isSelected(MEMBERS_DATA.BASIA.value)}
                    onClick={handleAvatarClick(MEMBERS_DATA.BASIA)}
                >
                    <BasiaSelectedFrame />
                </InteractiveAvatar>
                <InteractiveAvatar
                    image={images.przemek.childImageSharp.gatsbyImageData}
                    value={MEMBERS_DATA.PRZEMEK.value}
                    isSelected={isSelected(MEMBERS_DATA.PRZEMEK.value)}
                    onClick={handleAvatarClick(MEMBERS_DATA.PRZEMEK)}
                >
                    <PrzemekSelectedFrame />
                </InteractiveAvatar>
            </SRightWrapper>
        </SContainer>
    );
};
