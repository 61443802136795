import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { subscribeNewsletter } from 'api/newsletter';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { HeaderThird } from 'components/header-third';
import { Input } from 'components/input-new';
import { Overlay } from 'components/overlay';
import { CONSTANTS } from 'constants/styles/constants';

import { FORM_FIELDS, validationSchema } from './validation';

const SSubContainer = styled.div`
    border: 3px solid var(--additional-color);
    border-radius: 9.625rem;
    height: 19.875rem;
    padding: 4.375rem 10.5rem;

    ${CONSTANTS.MEDIA.max1024`
        padding: 3.375rem 5.5rem;
        height: 100%;
    `}

    ${CONSTANTS.MEDIA.max768`
        padding: 3.375rem 2.5rem;
        border-radius: 6rem;
    `}

    ${CONSTANTS.MEDIA.max500`
        padding: 3.375rem 1.25rem;
        border-radius: 4rem;
    `}
`;

const SForm = styled.form`
    display: flex;
    gap: 1.5rem;

    .inputContainer {
        width: 100%;
        border: 2px solid transparent;

        & > input {
            :focus {
                outline: none;
                border-color: var(--additional-color);
            }
        }

        ${CONSTANTS.MEDIA.max1024`
            max-width: 60%;
        `}

        ${CONSTANTS.MEDIA.max768`
            max-width: 90%;
        `}
    }

    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
        align-items: center;
        gap: 2.5rem;
    `}
`;

const SHeaderThird = styled(HeaderThird)`
    font-size: 1.75rem;
    line-height: 2.375rem;
    margin-bottom: 2rem;
    color: var(--white-color);
    text-align: center;
`;

const SButton = styled(Button)`
    height: 3rem;
    margin-top: 1.6rem;

    ${CONSTANTS.MEDIA.max1024`
        width: 30%;
    `}

    ${CONSTANTS.MEDIA.max768`
        width: 60%;
    `}
`;

export const NewsletterBanner = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { formatMessage } = useIntl();
    const { control, formState, handleSubmit } = useForm({
        resolver: validationSchema,
        defaultValues: {
            [FORM_FIELDS.FIRST_NAME]: '',
            [FORM_FIELDS.EMAIL]: '',
        },
    });

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            await subscribeNewsletter(data);
            toast.success(formatMessage({ id: 'newsletter.success' }));
        } catch {
            toast.error(formatMessage({ id: 'newsletter.error' }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <SSubContainer>
                <SHeaderThird>
                    <FormattedMessage id="newsletter.newsletterAbout" />
                </SHeaderThird>
                <SForm>
                    <Input
                        containerClassName="inputContainer"
                        name={FORM_FIELDS.FIRST_NAME}
                        control={control}
                        label={formatMessage({
                            id: 'newsletter.name',
                        })}
                        error={formState.errors[FORM_FIELDS.FIRST_NAME]}
                        isRequired
                    />
                    <Input
                        containerClassName="inputContainer"
                        name={FORM_FIELDS.EMAIL}
                        control={control}
                        label={formatMessage({
                            id: 'newsletter.emailAddress',
                        })}
                        error={formState.errors[FORM_FIELDS.EMAIL]}
                        isRequired
                    />
                    <SButton
                        variant="additional"
                        onClick={handleSubmit(onSubmit)}
                    >
                        <FormattedMessage id="newsletter.subscribe" />
                    </SButton>
                </SForm>
                <Overlay show={isLoading} />
            </SSubContainer>
        </Container>
    );
};
