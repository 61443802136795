import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { UnderlinedSectionTitle } from 'components/underlined-section-title';
import { CONSTANTS } from 'constants/styles/constants';
import { array, bool, func } from 'prop-types';

import { SendApplicationCard } from './components/send-application-card';
import { VacancyCard } from './components/vacancy-card';

const SContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${CONSTANTS.MEDIA.max800`
        padding-top: 5rem;
    `}
`;

const SSubContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ isEmpty }) =>
        isEmpty ? '1fr' : 'repeat(4, 1fr)'};
    gap: 1.5rem 1.5rem;

    > :first-child {
        grid-column: 1 / 3;
    }

    ${CONSTANTS.MEDIA.max1200`
        grid-template-columns: repeat(3, 1fr);
    `}

    ${CONSTANTS.MEDIA.max800`
        grid-template-columns: repeat(2, 1fr);
    `}

    ${CONSTANTS.MEDIA.max600`
        grid-template-columns: 1fr;

        > :first-child {
            grid-column: unset;
        }

        > :not(:first-child) {
            margin: 0 1.75rem;
        }
    `}
`;

export const VacanciesGrid = forwardRef(({ offers, isPL, openModal }, ref) => {
    return (
        <SContainer ref={ref}>
            <UnderlinedSectionTitle label="view-careers.vacancies.title" />
            <SSubContainer isEmpty={offers.length === 0}>
                {offers
                    .sort(
                        (
                            { node: { isFeatured: isFeaturedA } },
                            { node: { isFeatured: isFeaturedB } },
                        ) => isFeaturedB - isFeaturedA,
                    )
                    .map(({ node }) => {
                        const { title, slug, price, pricePl, id, isFeatured } =
                            node;
                        return (
                            <VacancyCard
                                key={id}
                                slug={slug}
                                jobTitle={title}
                                salary={isPL ? pricePl : price}
                                isFeatured={isFeatured}
                            />
                        );
                    })}
                <SendApplicationCard openModal={openModal} />
            </SSubContainer>
        </SContainer>
    );
});

VacanciesGrid.propTypes = {
    isPL: bool.isRequired,
    offers: array.isRequired,
    openModal: func.isRequired,
};

VacanciesGrid.displayName = 'VacanciesGrid';
