import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const SContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;

    ${CONSTANTS.MEDIA.max900`
        position: initial;
        margin: 0 25%;
    `};

    ${CONSTANTS.MEDIA.max500`
        position: initial;
        margin: 0;
    `};
`;

const STeamMemberRed = styled.div`
    position: absolute;
    bottom: 0;
    left: -4rem;
    z-index: 0;

    ${CONSTANTS.MEDIA.max1366`
       left: 2rem;
    `};

    ${CONSTANTS.MEDIA.max900`
        display: initial;
        position: initial;
        margin-left: 1.25rem;
        
        > : first-child {
            height: 378px !important;
        }
    `};

    ${CONSTANTS.MEDIA.max700`
        display: none;
    `}

    ${CONSTANTS.MEDIA.max500`
        display: initial;
        margin-left: 0;

       > :first-child {
            width: 192px !important;
        }

       * > img {
           object-position: 100%;
           width: 192px;
       }
    `};
`;

const STeamMemberBlack = styled.div`
    position: absolute;
    bottom: 0;
    left: 12.75rem;
    z-index: 1;

    ${CONSTANTS.MEDIA.max1366`
        display: none;
    `};

    ${CONSTANTS.MEDIA.max900`
        display: initial;
        position: initial;
        margin-left: -10rem
    `};

    ${CONSTANTS.MEDIA.max700`
        margin-left: 0;
    `}

    ${CONSTANTS.MEDIA.max500`
        margin-left: -7rem;

       > :first-child {
            width: 292px !important;
        }

       * > img {
           object-position: 0;
           width: 292px;
       }
    `};
`;

export const TeamMembers = () => {
    const { formatMessage } = useIntl();
    const images = useStaticQuery(graphql`
        {
            teamMemberRed: file(
                relativePath: { eq: "team/team-member-red.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 369
                        formats: [WEBP]
                        layout: FIXED
                    )
                }
            }
            strongman: file(relativePath: { eq: "team/strongman.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 438
                        formats: [WEBP]
                        layout: FIXED
                    )
                }
            }
        }
    `);

    return (
        <SContainer>
            <STeamMemberRed>
                <GatsbyImage
                    alt={formatMessage({
                        id: 'view-careers.getInTouch.teamMemberInRed',
                    })}
                    image={images.teamMemberRed.childImageSharp.gatsbyImageData}
                />
            </STeamMemberRed>
            <STeamMemberBlack>
                <GatsbyImage
                    alt={formatMessage({
                        id: 'view-careers.getInTouch.teamMemberInBlack',
                    })}
                    image={images.strongman.childImageSharp.gatsbyImageData}
                />
            </STeamMemberBlack>
        </SContainer>
    );
};
