import React from 'react';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const SFramedImage = styled.div`
    position: relative;
    width: 50.5rem;
    height: 20rem;
    border: 3px solid var(--additional-color);
    border-radius: 12.9rem;

    ${CONSTANTS.MEDIA.max900`
        width: 40.5rem;
        height: 17.2rem;
    `}

    ${CONSTANTS.MEDIA.max768`
        width: 31.5rem;
        height: 13.4rem;
    `}

    ${CONSTANTS.MEDIA.max600`
        width: 25.5rem;
        height: 10.4rem;
        border-width: 1px;
    `}

    ${CONSTANTS.MEDIA.max420`
        width: 20.5rem;
        height: 8.5rem;
    `}
`;

const SGatsbyImage = styled(GatsbyImage)`
    bottom: 4rem;

    ${CONSTANTS.MEDIA.max900`
        width: 94%;
        bottom: 2rem;
    `}

    ${CONSTANTS.MEDIA.max768`
        width: 96%;
    `}

    ${CONSTANTS.MEDIA.max600`
        bottom: 2rem;
    `}

    ${CONSTANTS.MEDIA.max420`
        bottom: 1.5rem;
    `}
`;

export const TeamFramedImage = () => {
    const image = useStaticQuery(graphql`
        {
            team: file(relativePath: { eq: "careers/team-members.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return (
        <SFramedImage>
            <SGatsbyImage
                image={image.team.childImageSharp.gatsbyImageData}
                alt="team members group photo"
            />
        </SFramedImage>
    );
};
