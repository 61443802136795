import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { SocialMedia } from 'components/social-media';
import { UnderlinedSectionTitle } from 'components/underlined-section-title';
import { CONSTANTS } from 'constants/styles/constants';
import MailIcon from 'svgs/careers/ic-mail.svg';

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 5.75rem;

    > :first-child {
        text-align: start;
        margin-bottom: 2rem;
    }

    ${CONSTANTS.MEDIA.max900`
       align-items: center;
    `};

    ${CONSTANTS.MEDIA.max500`
        > :last-child {
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0 3rem;
        }
    `};
`;

const SEmailLinkWrapper = styled.a`
    text-decoration: none;
    color: var(--white-color);
`;

const SEmailUsContainer = styled.div`
    max-width: 26rem;
    margin-bottom: 2rem;
    padding: 1rem 1.25rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 2rem;
    background: var(--tertiary-color-10);
`;

const SEmailUsContentContainer = styled.div`
    display: flex;
    flex-direction: column;

    > :first-child {
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
    }

    > :nth-child(2) {
        font-size: 1.375rem;
        font-weight: 700;
    }
`;

const SFollowUsSpan = styled.span`
    color: var(--additional-color);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-bottom: 1.5rem;
`;

export const ContactInfo = () => {
    const { formatMessage } = useIntl();

    return (
        <SContainer>
            <UnderlinedSectionTitle label="view-careers.getInTouch.title" />
            <SEmailLinkWrapper
                href={`mailto:${formatMessage({ id: 'emails.career' })}`}
            >
                <SEmailUsContainer>
                    <MailIcon />
                    <SEmailUsContentContainer>
                        <FormattedMessage id="view-careers.getInTouch.writeToUs" />
                        <FormattedMessage id="emails.career" />
                    </SEmailUsContentContainer>
                </SEmailUsContainer>
            </SEmailLinkWrapper>
            <SFollowUsSpan>
                <FormattedMessage id="view-careers.getInTouch.followUs" />
            </SFollowUsSpan>
            <SocialMedia isOutlined />
        </SContainer>
    );
};
