const resolveMemberData = (value) => {
    const path = 'view-careers.teamOpinion.teamMembers';
    const data = {
        value,
        quote: `${path}.${value}.quote`,
        fullName: `${path}.${value}.fullName`,
        role: `${path}.${value}.role`,
    };
    return data;
};

export const MEMBERS_DATA = {
    BARTEK: resolveMemberData('bartek'),
    MAGDA: resolveMemberData('magda'),
    BASIA: resolveMemberData('basia'),
    PRZEMEK: resolveMemberData('przemek'),
};
