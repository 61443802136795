import React from 'react';
import { CareersView } from 'views/careers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/careers.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const EMPTY_JOB_OFFERS = 'empty-job-offers';

const JobOfferPage = (props) => {
    const { location, data } = props;
    const edges = data?.allContentfulJobOffer?.edges;
    const offers = edges.filter((item) => item.node.slug !== EMPTY_JOB_OFFERS);

    return (
        <Layout location={location} messages={messages}>
            <CareersView offers={offers} location={location} />
        </Layout>
    );
};

JobOfferPage.propTypes = {
    location: object.isRequired,
    data: object.isRequired,
};

export default JobOfferPage;

export const Head = () => <SEO tags={TAGS.CAREERS} />;

export const pageQuery = graphql`
    query EnJobsOffersQuery {
        allContentfulJobOffer(
            sort: { isFeatured: ASC }
            filter: { node_locale: { eq: "en-US" } }
        ) {
            edges {
                node {
                    id
                    title
                    slug
                    price
                    pricePl
                    isFeatured
                }
            }
        }
    }
`;
