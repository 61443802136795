import React, { useState } from 'react';
import { Dialog } from 'components/dialog';
import { resolveDialogContent } from 'components/dialog/utilities';
import { DialogContent } from 'components/dialog-content';
import { Overlay } from 'components/overlay';
import { FORM_STATUSES } from 'constants/form-statuses';
import { func, object } from 'prop-types';

import { RecruitForm } from './components/recruit-form';
import { customDialogContent } from './constants';

export const RecruitModalForm = ({ dialogRef, enableBodyScroll }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFormScrollDisabled, setIsFormScrollDisabled] = useState(false);
    const [formStatus, setFormStatus] = useState(FORM_STATUSES.IDLE);

    const toggleFormDisableScroll = (value) => setIsFormScrollDisabled(value);
    const onFormSubmit = (status) => setFormStatus(status);
    const onClose = () => {
        if (formStatus === FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR) {
            window.location.reload();
            return;
        }

        dialogRef?.current?.close();
        enableBodyScroll();
        setFormStatus(FORM_STATUSES.IDLE);
    };

    return (
        <Dialog ref={dialogRef}>
            {formStatus === FORM_STATUSES.IDLE ? (
                <RecruitForm
                    setIsLoading={setIsLoading}
                    isScrollDisabled={isFormScrollDisabled}
                    toggleDisableScroll={toggleFormDisableScroll}
                    onClose={onClose}
                    onFormSubmit={onFormSubmit}
                />
            ) : (
                <DialogContent
                    {...resolveDialogContent({
                        formStatus,
                        customDialogContent,
                    })}
                    firstParagraphMargin
                    onClose={onClose}
                />
            )}
            <Overlay show={isLoading} inComponent />
        </Dialog>
    );
};

RecruitModalForm.propTypes = {
    dialogRef: object.isRequired,
    enableBodyScroll: func.isRequired,
};
